// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/index';
import { socialProof } from './custom/social-proof.js';
import '/vendor/js/flipclock.js';

jQuery(function () {
  $('.clock').FlipClock(600, {
    countdown: true,
  });
  offer.getState(function (data) {
    const iso_code = offer.getCountryIsoCode();
    if (iso_code == 'IE') {
      $('.city-1').html('Limerick');
      $('.city-2').html('Waterford');
      $('.city-3').html('Sligo');
      iso('IE', '/static/common/shared/country-flags/flag-ie.svg', '/static/common/shared/made-desk-ie.svg');
    } else {
      iso('UK', '/static/common/shared/country-flags/flag-uk.svg', '/static/common/shared/made-desk-uk.svg');
    }

    function iso(text, flag, label) {
      $('.dynamicCount').html(text);
      $('.dynamicFlag').attr('src', flag);
      $('.made-for').attr('src', label);
    }

    socialProof(iso_code);
  });
});
